import getConfig from '../config';
import { cdnClient, client, ContentTypes, Query, ResponseData } from '../galaxyClient';
import { extractPaginatedUrl } from '../helpers/pagination';

export type Initiative = ResponseData<ContentTypes, '/v2/initiatives/{initiativeId}', 'get'>;
type InitiativesFilter = Query<ContentTypes, '/v2/initiatives/', 'get'>;

export const getInitiatives = async (filters?: InitiativesFilter, paginationUrl?: string) => {
  if (paginationUrl) {
    const { API_URL } = await getConfig();
    const { data } = await client().fetch<ContentTypes, '/v2/initiatives/', 'get'>(
      extractPaginatedUrl(paginationUrl, API_URL),
    );
    return data;
  }
  const { data } = await client().content.GET('/v2/initiatives/', {
    params: {
      query: filters,
    },
  });
  return data;
};

export const getInitiativesChunked = async (
  filters: InitiativesFilter,
  key: keyof InitiativesFilter,
) => {
  const { pages } = await cdnClient().content.GET_CHUNKED('/v2/initiatives/', {
    params: {
      query: filters,
    },
    chunkKey: key,
  });
  return { result: pages.flatMap((page) => page.result) };
};

export const getInitiative = async (
  id: string,
  { skipContentfulData }: { skipContentfulData?: boolean } = {},
) => {
  const { data } = await cdnClient().content.GET('/v2/initiatives/{initiativeId}', {
    params: {
      path: {
        initiativeId: id,
      },
      query: {
        skipContentfulData,
      },
    },
  });

  return data;
};

type PillarsFilters = Query<ContentTypes, '/v2/pillars/', 'get'>;
export const getPillars = async (query: PillarsFilters) => {
  const { data } = await cdnClient().content.GET('/v2/pillars/', {
    params: {
      query,
    },
  });

  return data;
};

type ApproachesFilters = Query<ContentTypes, '/v2/approaches/', 'get'>;
export const getApproaches = async (query: ApproachesFilters) => {
  const { data } = await cdnClient().content.GET('/v2/approaches/', {
    params: {
      query,
    },
  });

  return data;
};
